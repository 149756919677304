<template>
  <main>
    <div class="main-wrap">
      <section>
        <article v-if="content">
          <h1>{{ content.title }}</h1>
          <div class="meta-header">
            <div class="description">
              <span class="type type-article" v-if="content.type === 'article'">
                {{ content.isPubliclyEditable ? 'Публичная' : 'Авторская' }} статья
              </span>
              <span class="type type-discussion" v-if="content.type === 'discussion'">
                Дискуссия
              </span>
              <span class="type type-event" v-if="content.type === 'event'">
                Публичное событие
              </span>
              <div class="stats">
                <span class="views">
                  {{ content.views }}
                  <img src="../assets/icons/views.svg" alt="Просмотры" draggable="false">
                </span>
                <span class="rating" v-if="content.hasOwnProperty('rating')">
                  <img
                    src="../assets/icons/rating-up.svg"
                    alt="Повысить рейтинг"
                    draggable="false"
                    :class="{
                      clickable: true,
                      active: !content['yourRating'] || content['yourRating'] === 1
                    }"
                    @click="rateUpClicked()"
                  >
                  {{ content.rating }}
                  <img
                    src="../assets/icons/rating-down.svg"
                    alt="Понизить рейтинг"
                    draggable="false"
                    :class="{
                      clickable: true,
                      active: !content['yourRating'] || content['yourRating'] === -1
                    }"
                    @click="rateDownClicked()"
                  >
                </span>
                <span class="comments" v-if="content.comments.length > 0">
                  {{ content.comments.length }}
                  <img src="../assets/icons/comments.svg" alt="Комментарии" draggable="false">
                </span>
              </div>
            </div>
            <span class="date" v-if="!content.dateUpdated">
              {{ getPublishDate() }}
            </span>
            <span class="date" v-if="content.dateUpdated">
              {{ getPublishDateFor(content.dateUpdated) }}
            </span>
            <span class="tags" v-if="content.tags && content.tags.length > 0">
                <router-link
                  :to="{ name: 'tag-card-list', params: { slug: tag } }"
                  v-for="tag in content.tags"
                  :key="tag"
                >
                  #{{ tag }}
                </router-link>
              </span>
            <Author
              :info="content.author"
              description="Автор материала"
              :clickable="true"
            />
          </div>
          <div class="content">
            <!--suppress HtmlUnknownTarget -->
            <img v-if="content['cover'] && !content.isModern" class="content-cover" :src="`${$root['globalParams']['developerBaseURI']}${content['cover']}`" alt="Обложка материала">
            <div v-if="content.type === 'event'" class="event-info">
              <span v-for="(displayName, paramName) in getRelatedEventParams()" :key="paramName">
                <strong>{{ displayName }}</strong>:
                {{ (paramName !== 'phone' && paramName !== 'website') ? content[paramName] : '' }}
                <a
                  v-if="paramName === 'phone' || paramName === 'website'"
                  :href="`${paramName === 'phone' ? `tel:${encodeURIComponent(content[paramName])}` : content[paramName]}`"
                >
                  {{ content[paramName] }}
                </a>
              </span>
            </div>
            <div v-if="content.body && !content.isModern" class="body" v-html="getCompatibleHTML(content.body)" />
            <div v-if="content.body && content.isModern" class="body" v-html="getModernHTML(content.body)" />
          </div>
          <div class="meta-footer">

          </div>
        </article>
        <div class="comments-block" v-if="content">
          <span class="block-header">Комментарии</span>
          <div class="comments-list" v-if="content.comments.length > 0">
            <div class="comment" v-for="(comment, index) in content.comments" :key="index">
              <Author
                :info="comment.author"
                :clickable="true"
                :description="getPublishDateTimeFor(comment.date)"
                class="mini-avatar"
              />
              <p v-if="comment.format === 'plain'" class="comment-body plain">{{ comment.body }}</p>
              <div class="comment-body html" v-if="comment.format === 'html'" v-html="comment.body"/>
              <span class="rating">
                <img src="../assets/icons/rating-up.svg" alt="Повысить рейтинг" draggable="false">
                {{ comment.rating }}
                <img src="../assets/icons/rating-down.svg" alt="Понизить рейтинг" draggable="false">
              </span>
            </div>
          </div>
          <div class="no-comments" v-if="content.comments.length === 0">
            Комментариев нет
          </div>
          <div class="leave-comment" v-if="$root.auth">
            <textarea rows="4" v-model="commentField"/>
            <div class="button publish-comment" @click="publishCommentClicked">Оставить комментарий</div>
          </div>
        </div>
        <LoadingBlock v-if="!content"/>
      </section>
      <aside>
        <div class="tools">
          <router-link
            :to="{ name: `edit-${type}`, params: { slug: slug } }"
            class="button edit"
            v-if="$root.auth && this.content && this.content.isEditable"
          >
            Редактировать
          </router-link>
        </div>
        <!--<div class="advt">
          Рекламный блок
        </div>-->
      </aside>
    </div>
    <!--<div class="similar-articles" v-if="false">
      Похожие статьи
    </div>-->
  </main>
</template>

<script>
import Author from '@/components/Shared/Author'
import LoadingBlock from '@/components/Shared/LoadingBlock'

import { Editor } from 'tiptap'
import Heading from '@/tiptap-helperia-extensions/Heading'
import Bold from '@/tiptap-helperia-extensions/Bold'
import Italic from '@/tiptap-helperia-extensions/Italic'
import Underline from '@/tiptap-helperia-extensions/Underline'
import Image from '@/tiptap-helperia-extensions/Image'
import Video from '@/tiptap-helperia-extensions/Video'
import ListItem from '@/tiptap-helperia-extensions/ListItem'
import BulletList from '@/tiptap-helperia-extensions/BulletList'
import OrderedList from '@/tiptap-helperia-extensions/OrderedList'
import Link from '@/tiptap-helperia-extensions/Link'
import Blockquote from '@/tiptap-helperia-extensions/Blockquote'
import CodeBlock from '@/tiptap-helperia-extensions/CodeBlock'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ContentReader',
  components: { LoadingBlock, Author },
  props: ['type', 'slug'],

  data() {
    return {
      content: null,
      commentField: '',

      eventParams: {
        format: 'Формат',
        organizer: 'Организатор',
        phone: 'Телефон',
        website: 'Сайт мероприятия/организатора',
        address: 'Адрес проведения',
        date: 'Дата проведения',
        time: 'Время работы',
        price: 'Цена билета',
      }
    }
  },

  created() {
    this.getContent()
  },

  watch: {
    $route: 'getContent',
    '$root.auth': 'getContent',
  },

  methods: {
    getContent() {
      this.content = null
      this.$root.contentName = null

      this.$root.makeAPIRequest('content', 'get', {
        type: this.type,
        slug: this.slug,
      })
        .then(response => {
          if(response.data.error) {
            if(response.data.handler) {
              // noinspection JSRedundantSwitchStatement
              switch (response.data.handler) {
                case 'not-found':
                  this.$router.replace({ name: 'not-found' })
                  return
              }
            }

            console.error(`Ошибка загрузки данных: ${response.data.message}`)
            alert('Ошибка загрузки данных с сервера. Пожалуйста, перезагрузите страницу.')
            return
          }

          this.content = response.data.result
          this.$root.contentName = this.content.title

          this.$root.setTitle(this.content.title)
          this.$root.setDescription(this.content.description)
          const canonicalURI = `/${this.$root.contentTypes[this.type].letter}/${this.slug}`
          this.$root.setCanonical(canonicalURI)
          if(this.content.cover) {
            this.$root.setMeta('property', 'og:image', `${this.$root.canonicalBaseURI}${this.content.cover}`)
          }
          if(this.content.author) {
            this.$root.setLink('author', `/u/${this.content.author.name}`)
          }
          if(this.content.tags) {
            this.$root.setKeywords(this.content.tags)
          }
        })
    },

    getPublishDate() {
      const date = new Date(this.content.datePublished * 1000)
      const dateString = `${date.getDate()} ${this.$root.monthNames[date.getMonth()]} ${date.getFullYear()}`
      return dateString
    },

    getPublishDateFor(timestamp) {
      const date = new Date(timestamp * 1000)
      const dateString = `${date.getDate()} ${this.$root.monthNames[date.getMonth()]} ${date.getFullYear()}`
      return dateString
    },

    getPublishDateTimeFor(timestamp) {
      const date = new Date(timestamp * 1000)
      let minutes = date.getMinutes()
      if(minutes < 10) {
        minutes = `0${minutes}`
      }
      const dateTimeString = `
        ${date.getDate()} ${this.$root.monthNames[date.getMonth()]} ${date.getFullYear()},
        ${date.getHours()}:${minutes}
      `
      return dateTimeString
    },

    getRelatedEventParams() {
      const relatedEventParams = {}
      for(const paramName in this.eventParams) {
        if(!Object.prototype.hasOwnProperty.call(this.eventParams, paramName)) {
          continue
        }

        if(this.content[paramName] === null) {
          continue
        }

        relatedEventParams[paramName] = this.eventParams[paramName]
      }
      return relatedEventParams
    },

    getCompatibleHTML(body) {
      if(process.env.NODE_ENV === 'production') {
        return body
      }

      const search = / src="\/public\/images\//g
      const replaceWith = ` src="${this.$root.developerBaseURI}/public/images/`
      const result = body.replace(search, replaceWith)
      return result
    },

    getModernHTML(body) {
      const json = JSON.parse(body)
      if(this.$root.globalParams.isDebug) {
        console.log(json)
      }
      const editor = new Editor({
        content: json,
        extensions: [
          new Heading(),
          new Bold(),
          new Italic(),
          new Underline(),
          new Image(),
          new Video(),
          new ListItem(),
          new BulletList(),
          new OrderedList(),
          new Link(),
          new Blockquote(),
          new CodeBlock(),
        ],
      })
      const html = editor.getHTML()
      editor.destroy()
      return html
    },

    rateUpClicked() {
      if(!this.$root.auth) {
        alert('Пожалуйста войдите, чтобы голосовать за материалы')
        return
      }

      if(this.content.yourRating && this.content.yourRating === 1) {
        this.rateContent(0)
        return
      }

      this.rateContent(1)
    },

    rateDownClicked() {
      if(!this.$root.auth) {
        alert('Пожалуйста войдите, чтобы голосовать за материалы')
        return
      }

      if(this.content.yourRating && this.content.yourRating === -1) {
        this.rateContent(0)
        return
      }

      this.rateContent(-1)
    },

    rateContent(rating) {
      this.$root.makeAPIRequest('content', 'rate', {
        type: this.type,
        slug: this.slug,
        rating,
      })
        .then((response) => {
          if(response.data.error) {
            console.error(`Ошибка голосования: ${response.data.message}`)

            switch(response.data.handler) {
              case 'is-banned':
                alert('Вы не можете голосовать: профиль заблокирован.')
                break
              case 'too-frequent-votes':
                alert('Вы пытаетесь голосовать слишком часто. Подождите несколько секунд и попробуйте снова.')
                break
            }
            return
          }

          const newYourRating = response.data.result.rated
          const oldYourRating = this.content.yourRating ? this.content.yourRating : 0
          const ratingAddition = newYourRating - oldYourRating

          this.content.rating += ratingAddition
          if(newYourRating === 0) {
            delete this.content.yourRating
          } else {
            this.content.yourRating = newYourRating
          }
        })
    },

    publishCommentClicked() {
      const trimmedComment = this.commentField.trim()
      if(trimmedComment.length < 1) {
        alert('Комментарий не может быть пустым')
        return
      }

      this.$root.makeAPIRequest('comment', 'publish', {
        type: this.type,
        slug: this.slug,
        text: trimmedComment,
      })
        .then(response => {
          if(response.data.error) {
            console.error(`Ошибка публикации комментария: ${response.data.message}`)
            alert(response.data.message)
            return
          }

          this.content.comments.push({
            date: Math.floor(new Date().getTime() / 1000),
            body: trimmedComment,
            rating: 0,
            format: 'plain',
            author: {
              name: this.$root.auth.username,
              image: null,
            }
          })
          this.commentField = ''
        })
    },
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';
  @import '../content-views.less';

  .advt {
    padding-top: 1em;
    position: sticky;
    top: 0;
  }

  .tools {
    .button {
      .big-button();
      background-color: @color-accent;
      display: block;
    }
  }

  .meta-header {
    margin-bottom: 1em;

    .description {
      display: flex;
      align-items: center;

      @media @mobile-l {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .type {
      margin-right: 1.5em;
      background-color: var(--type-color);
      color: @color-block-background;
      font-weight: bold;
      padding: 0.25em 1em;

      @media @mobile-l {
        margin-right: unset;
        margin-bottom: 0.5em;
      }
    }

    .stats {
      display: flex;
      opacity: 0.6;

      &>*+* {
        margin-left: 1em;
      }

      .rating img {
        &.active {
          .black-to-accent();
        }

        &.clickable {
          cursor: pointer;
        }
      }
    }

    .author {
      margin-top: 1em;
    }
  }

  .meta-footer {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .tags {
    margin: 0.5em 0;
    display: flex;
    flex-wrap: wrap;
    overflow-wrap: break-word;
    line-height: 1.5;

    * {
      margin-right: 0.25em;

      &:last-child {
        margin-right: unset;
      }
    }

    a {
      max-width: 100%;
    }
  }

  .content-cover {
    width: 100%;
    max-height: 1200px;
  }

  .event-info {
    border: 1px solid @color-accent;
    padding: 1em;
    display: flex;
    flex-direction: column;
    margin-top: 0.75em;
    overflow-wrap: break-word;

    span+span {
      margin-top: 0.5em;
    }
  }

  .block-header {
    display: block;
    font-family: @font-set-header;
    font-size: @font-size-header;
  }

  .comments-block {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
  }

  .comments-list {
    display: flex;
    flex-direction: column;
  }

  .no-comments {
    margin: 1em 0;
    font-size: @font-size-normal;
    font-family: @font-set-header;
    opacity: 0.5;
  }

  .comment {
    padding: 1.5em 0;

    & + & {
      border-top: 1px solid @color-border;
    }

    .rating {
      opacity: 0.6;

      img {
        .black-to-accent();
      }
    }
  }

  .comment-body {
    overflow-wrap: break-word;

    &.plain {
      white-space: pre-wrap;
    }
  }

  .leave-comment {
    display: flex;
    flex-direction: column;

    textarea {
      border: 1px solid @color-accent;
      width: 100%;
      padding: 0.5em;
      background-color: unset;
      font-family: @font-set-main;
      font-size: 12pt;
      resize: none;
      box-sizing: border-box;
    }
  }

  .publish-comment {
    .big-button();
    background-color: @color-accent;
    font-weight: normal;
    margin-top: 1em;
    align-self: flex-start;
  }

  .date {
    display: block;
    margin-top: 0.75em;
  }
</style>

<style lang="less">
  @import '../content-body.less';
</style>
